import React from "react";
import headerLottie from "../assets/lottie/schedule.json";
import Lottie from "lottie-react";
import Footer from "../component/Footer";
const Schedule = () => {
  return (
    <div>
      <section class="bg-white  antialiased">
        <div class="max-w-screen-xl px-4 py-8 mx-auto  lg:py-24 ">
          <div class="max-w-3xl mx-auto text-center">
            <h1 class="text-4xl font-extrabold leading-tight tracking-tight text-gray-900 ">
              2024 Softball Schedule
            </h1>
            <Lottie
              className=" mx-auto w-[150px]  lg:w-[200px]"
              animationData={headerLottie}
            />
            <a
              href="https://web.gc.com/teams/X3v9emQIX1eQ/live"
              target="_blank"
              class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-red-500 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
            >
              <svg
                class="mr-2 -ml-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
              </svg>
              Live Stream Games
            </a>
          </div>
          <div class="max-w-3xl mx-auto text-center mt-5">
            <a
              href="https://www.facebook.com/drew.busby.9"
              target="_blank"
              class="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-black hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900"
            >
              <svg
                class="mr-2 -ml-1 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
              </svg>
              Live Stream Club Games
            </a>
          </div>

          <div class="flow-root max-w-3xl mx-auto mt-8 sm:mt-12 lg:mt-16">
            <div class="-my-4 divide-y divide-gray-200 dark:divide-gray-700">
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/5 - TBD
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Shawnee
                  </a>
                </h3>
              </div>

              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/7 - TBD
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Yukon
                  </a>
                </h3>
              </div>

              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/9 - 5:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Deer Creek
                  </a>
                </h3>
              </div>

              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/12 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnan City West @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/13 - 4:00 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ BTW
                  </a>
                </h3>
              </div>

              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/16 - TBD
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    BA Tournament : Putnam City North @ Tulsa
                  </a>
                </h3>
              </div>

              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/17 - TBD
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    BA Tournament : Putnam City North @ Tulsa
                  </a>
                </h3>
              </div>

              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/19 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Bethany @ Putnam City North
                  </a>
                </h3>
              </div>

              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/20 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Southmoore @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/22 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Yukon @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/23 - TBD
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Murray State University : Putnam City North @ Tishamingo, OK
                  </a>
                </h3>
              </div>

              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/24 - TBD
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Murray State University : Putnam City North @ Tishamingo, OK
                  </a>
                </h3>
              </div>

              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/27 - 7:00 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Broken Arrow @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  8/29 - 4:00 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Moore
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/3 - 6:00 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ El Reno
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/5 - 5:00 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Sand Springs
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/9 - TBD
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Del City
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/10 - 5:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Sand Springs @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/12 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Newcastle
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/16 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/17 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ South Moore
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/19 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Moore @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/21 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Yukon
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/20 - TBD
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Chickasha Fesival @ Chickasha
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/21 - TBD
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Chickasha Fesival @ Chickasha
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/23 - 7:00 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Broken Bow
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/24 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Shawnee @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/26 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Yukon
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  9/30 - 6:00 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Choctaw @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  10/1 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Deer Creek @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  10/3 - 5:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Ed Santa Fe
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  10/4 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Norman @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  10/5 - 10:30 AM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    OC University Festival @ OCU
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  10/7 - 4:00 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Putnam City North @ Shawnee
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  10/8 - 6:30 PM
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    El Reno @ Putnam City North
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  10/9 - TBD
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    Regionals
                  </a>
                </h3>
              </div>
              <div class="flex flex-col gap-2 py-4 sm:gap-6 sm:flex-row sm:items-center">
                <p class="w-32 text-lg font-normal text-gray-500 sm:text-right dark:text-gray-400 shrink-0">
                  10/16 - TBD
                </p>
                <h3 class="text-lg font-semibold text-gray-900 ">
                  <a
                    href="https://www.maxpreps.com/ok/oklahoma-city/putnam-city-north-panthers/softball/fall/schedule/"
                    class="hover:underline"
                  >
                    State @ Hall of Fame (OKC)
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div class="px-4 mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg lg:px-36">
          <span class="font-semibold text-gray-400 uppercase">Contact Me</span>
          <div class="flex flex-wrap justify-center items-center mt-8 text-gray-500 ">
            <a
              href="https://x.com/savannahmtz_3"
              target="_blank"
              className="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                id="Layer_1"
                class="h-10"
                viewBox="0 0 24 24"
              >
                <path d="M14.095479,10.316482L22.286354,1h-1.940718l-7.115352,8.087682L7.551414,1H1l8.589488,12.231093L1,23h1.940717  l7.509372-8.542861L16.448587,23H23L14.095479,10.316482z M11.436522,13.338465l-0.871624-1.218704l-6.924311-9.68815h2.981339  l5.58978,7.82155l0.867949,1.218704l7.26506,10.166271h-2.981339L11.436522,13.338465z" />
              </svg>
            </a>
            <a
              // href="#"
              onClick={() =>
                (window.location = "mailto:Savannahcmartinez09@gmail.com")
              }
              class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="#9575cd"
                class="h-11"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-11"
                  fill="#9575cd"
                  viewBox="0 0 24 24"
                ></svg>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 9v.906a2.25 2.25 0 01-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 001.183 1.981l6.478 3.488m8.839 2.51l-4.66-2.51m0 0l-1.023-.55a2.25 2.25 0 00-2.134 0l-1.022.55m0 0l-4.661 2.51m16.5 1.615a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V8.844a2.25 2.25 0 011.183-1.98l7.5-4.04a2.25 2.25 0 012.134 0l7.5 4.04a2.25 2.25 0 011.183 1.98V19.5z"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/savannahmartinez_3/"
              target="_blank"
              class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-11"
                fill="#f44336"
                viewBox="0 0 24 24"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </a>
            {/* <a
                href="#"
                class="mr-5 mb-5 lg:mb-0 hover:text-gray-800 dark:hover:text-gray-400"
              ></a> */}
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Schedule;

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import pic30 from "../assets/JPEG/pic30.jpeg";
import Lottie from "lottie-react";
import awesome from "../assets/lottie/awesome.json";

function PhotoHero() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: awesome,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className=" mx-auto text-center md:max-w-screen-md lg:max-w-screen-lg">
      <div className="relative flex justify-center text-center">
        <div className="absolute inset-0 z-10">
          <Lottie options={defaultOptions} height={1000} width={1000} />{" "}
          {/* Adjust height/width */}
        </div>
        <div className="p-1 md:p-2 flex justify-center text-center">
          <LazyLoadImage
            alt="gallery"
            className="h-auto max-w-full rounded-lg object-cover object-center"
            style={{ width: "60%" }} // Adjust the width as needed
            src={pic30}
          />
        </div>
      </div>
    </div>
  );
}

export default PhotoHero;
